import * as React from "react"
import '../styles/main.scss'
import Layout from "../components/layouts/layout";
import { SeoC } from "../components/utils/seo";
import Portfolio from "../components/portfolio/portfolio";
import Welcome from "../components/home/home";
import { graphql } from "gatsby";

// markup
const IndexPage = ({data}) => {

  const works = data.allWpReel.edges;

  return (
    <Layout>
      <div className="home">
        <Welcome />
        <Portfolio data={works} />
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SeoC />
)

export default IndexPage

export const AllWorkQuery = graphql`
  query {
    allWpReel(sort: {fields: date, order: DESC}) {
    edges {
      node {
        id
        link
        title
        date
        content
        databaseId
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        reels {
        agency
        client
        director
      }
      }
    }
  }
  }
`;

