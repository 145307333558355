// import { Link } from 'gatsby'
import React from 'react'
import Logo from '../utils/logo'
import BgVid from '../../images/vid/ppr.mp4'

 const Welcome = () => {

    return (
        <div className="splash">
            <div className="hello">
                <Logo />
                <h1>Music for commercials</h1>
            </div>
            <video className="home-vid" src={BgVid} autoPlay loop playsInline muted />
        </div>
    )
}

export default Welcome
